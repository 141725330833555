import React, { useEffect } from "react";

const PLP = () => {
    useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();

        if (/iphone|ipad/.test(userAgent)) {
            // Redirect for iOS devices
            window.location.href = "https://apps.apple.com/sa/app/leem-shop-womens-fashion/id1501163845";
        } else if (/android/.test(userAgent)) {
            // Redirect for Android devices
            window.location.href = "https://play.google.com/store/apps/details?id=com.leem&pli=1";
        } else {
            window.location.href = "https://www.leem.com/"
        }
    }, []);
    return <></>;
};

export default PLP;
